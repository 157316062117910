$(".unbox__content").slick({
  slidesToShow: 1,
  autoplay: false,
  slidesToScroll: 1,
  arrows: true,
  dots: true,
  infinite: false,
  responsive: [
    {
      breakpoint: 999,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: 550,
      settings: {
        arrows: true,
        slidesToShow: 1,
      },
    },
  ],
});
